<template>
  <swiper ref="swiperComponentRef" class="swiper" :options="swiperComponentOption">
    <swiper-slide v-for="item in dataList" :key="item.scenicSpotId">
      <div class="scenic-silder">
        <img :src="item.filePath" class="image">
        <div class="title">{{ item.scenicSpotName }}</div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperComponentOption: {
        autoplay: {
          delay: 4 * 1000,
          disableOnInteraction: false
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          dynamicBullets: true,
        },
      },
      swiperList: []
    };
  },
};
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.swiper,
.scenic-silder {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  bottom: 30px !important;
}
.scenic-silder {
  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px;
    background: rgba(3, 20, 57, 0.7);
    font-size: 14px;
    line-height: 26px;
    color: #BFE2F8;
    text-align: center;
    .overLine();
  }
  .image {
    width: 100%;
    height: 100%;
  }
}

</style>