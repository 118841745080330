import Vue from 'vue';
import Router from 'vue-router';

import Home from "@/view/bigscreen/home.vue";
import View from "@/view/bigscreen/view.vue";
import List from "@/view/bigscreen/list.vue";
import Video from "@/view/bigscreen/video.vue";

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: Home,
  },,
  {
    path: '/view',
    component: View,
  },
  {
    path: '/list',
    component: List,
  },
  {
    path: '/video',
    component: Video,
  }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})