<template>
  <FullScreenContainer>
    <div class="navigation">
      <div class="back_btn" @click="goBack"><span>返回</span></div>
    </div>

    <dv-border-box-8 class="stall_wrapper" :dur="10">
      <img src="@/assets/image/view/bg_stall.png" alt="" class="image">
      <ul class="trigger-wrap">
        <li class="trigger-item" v-for="item in 9" :key="item" @click="jumpPage(item)"></li>
      </ul>
    </dv-border-box-8>

  </FullScreenContainer>
</template>

<script>

import FullScreenContainer from "@/components/FullScreenContainer";
import ModalCard from "@/components/ModalCard";
import BoothCardItem from "./components/BoothCardItem";

import 'vue-happy-scroll/docs/happy-scroll.css';

export default {
  components: { FullScreenContainer,  ModalCard, BoothCardItem },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    jumpPage(stallNumber) {
      this.$router.push({
        path: "/list",
        query: { stallNumber }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
  color: #fff;

  .back_btn {
    width: 122px;
    height: 44px;
    padding-left: 55px;
    margin-right: 25px;
    background: url("@/assets/image/list/bg_back.png") no-repeat center center;
    background-size: 100% 100%;
    line-height: 44px;
    cursor: pointer;
  }

}

.stall_wrapper {
  padding: 10px;
  height: calc(100% - 84px);
  overflow: hidden;
  position: relative;

  .trigger-wrap {
    position: absolute;
    top: 37%;
    left: 55%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-flow: wrap;
    width: 29%;
    height: 24%;

    .trigger-item {
      width: 30%;
      height: 30%;
      cursor: pointer;
    }
    // background: red;
  }

  .image {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
    color: #fff;
  }

}
</style>
