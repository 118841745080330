import axios from 'axios';
import { notification } from 'ant-design-vue';

import { tansParams } from '@/utils/format';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
  baseURL: process.env.VUE_APP_REQUEST_URL,
  timeout: 10 * 1000
})

service.interceptors.request.use(config => {
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config;
})

service.interceptors.response.use(({ data }) => {
  const { code, msg } = data;
  if ( code != 200 ) {
    notification.warning({
      message: '警告',
      description: msg,
    })
    return Promise.reject(data);
  }
  
  return Promise.resolve(data);

})

export default service;