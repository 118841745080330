<template>
  <swiper ref="swiperComponentRef" class="swiper" :options="swiperComponentOption">
    <swiper-slide v-for="item in dataList" :key="item.productId">
      <div class="swiper-item">
        <img :src="item.filePath" alt="" class="image">
        <p>
          <span>{{ item.productName }}</span>
          <span>{{ item.title }}</span>
        </p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperComponentOption: {
        autoplay: {
          delay: 4 * 1000,
          disableOnInteraction: false
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          dynamicBullets: true,
        },
      },
      swiperList: []
    };
  },
  mounted() {
    setTimeout(_ => {
      this.$nextTick(_ => {
        this.$refs.swiperComponentRef?.$swiper.update();
      })
    }, 500);
  }
};
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.swiper,
.scenic-silder {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-pagination {
  bottom: 30px !important;
}

.swiper-item {
  min-height: 100px;
  display: flex;
  justify-content: space-between;

  .image {
    width: 160px;
    height: 100px;
    margin-right: 16px;
    background: #fff;
  }

  p {
    flex: 1;
    display: flex;
    flex-flow: column;
    // justify-content: space-between;

    span:nth-child(1) {
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #DCE9F4;
      line-height: 22px;
      .overLine(1);
    }

    span:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      color: #87C4EB;
      line-height: 20px;
      .overLine(3);
    }
  }
}
</style>