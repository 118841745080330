import Vue from 'vue';
import App from './App.vue';
import router from "./router";

import { Modal } from 'ant-design-vue';
import dataV from '@jiaminghi/data-view';

import "swiper/css/swiper.css";
import 'ant-design-vue/dist/antd.css';
import "@/assets/styles/index.less";
import "@/assets/styles/mixins.less";

Vue.config.productionTip = false;

Vue.use(dataV);
Vue.use(Modal);


new Vue({
  render: h => h(App),
  router
}).$mount('#app');
