<template>
  <section class="booth-card-item">
    <div :class="cssVar">
      <img class="image" :src="item.filePath" @click="close">
    </div>
    <ul class="booth-info">
      <li class="title"><p>{{ item.stallName }}</p></li>
      <li class="msg">
        <!-- <p>位置：<span>{{ item.stallNumber }}</span></p> -->
        <p>商户：<span>{{ item.businessName }}</span></p>
        <p>联系方式：<span>{{ item.phone }}</span></p>
      </li>
      <li class="desc">
        销售产品：
        <span>{{ item.productType }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["item", "source"],
  computed: {
    cssVar() {
      return this.source + "-image-warp";
    }
  },
  methods: {
    close() {
      console.log("zheli ");
      this.$emit("on-close", this.item)
    }
  }
}
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}
.booth-card-item {
  display: flex;
  color: #fff;

  .modal-image-warp {
    width: 224px;
    height: 200px;
    border: 1px solid #108FD9;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-image-warp {
    width: 160px;
    height: 176px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  
  .dialog-image-warp, .list-image-warp {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .booth-info {
    flex:1;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #87C4EB;

    .title p, .msg p{
      .overLine();
    }

    .title {
      padding-bottom: 16px;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #fff;
    }

    .desc {
      .overLine(4);
      padding-top: 8px;
    }
  }
}
</style>