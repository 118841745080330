<template>
  <a-modal
    v-model="openCardDialog"
    title=""
    wrapClassName="custom_dialog_wrapper"
    width="578px"
    :closable="false"
    :footer="null"
    :getContainer="getContainer"
    :bodyStyle="{
      padding: 0
    }"
  >  
    <section class="modal-box">
      <ul class="modal-title">
        <li>{{ title }}</li>
        <li><img src="@/assets/image/modal/img_close_btn.png" @click="close"></li>
      </ul>
      <div class="container">
        <slot></slot>
      </div>
    </section>
  </a-modal>
</template>

<script>
export default {
  props: ['title', 'visible', 'el'],
  data() {
    return {
    }
  },
  computed: {
    openCardDialog: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    getContainer() {
      return document.querySelector("#app");
    }
  }
}
</script>

<style lang="less" >
.custom_dialog_wrapper {
  .ant-modal-content {
    border-radius: 0 !important;
  }

  .modal-box {
    width: 578px;
    background-color: rgb(6, 23, 50);

    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: 0 11px 0 38px;
      background: rgb(6, 23, 50) url("@/assets/image/modal/bg_modal.png") no-repeat center center;
      background-size: 100% 100%;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
      text-shadow: 0px 0px 9px #158EFF;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .container {
      padding: 20px;
    }
  }
}
</style>