<template>
  <div style="width: 100%; height: 100%;">
    <dv-loading v-show="loading" class="loading">Loading...</dv-loading>
    <FullScreenContainer>
      <div class="screen-main">
        <div class="left-section">

          <!-- left top  -->
          <div class="left-top-card top-card">
            <div class="title">村情介绍</div>
            <div class="content">
              <video disablePictureInPicture disableRemotePlayback poster controls class="video" loop="loop"
                controlslist="nodownload noremoteplayback noplaybackrate" ref="customVideo">
                <source id="banner-video" :src="pageInfo.villageUrl" />
              </video>
            </div>
          </div>

          <!-- left middle -->
          <div class="left-middle-card middle-card">
            <div class="title">文旅产业</div>

            <div class="content">
              <ul class="floor-one">
                <li class="count-item">
                  <img src="@/assets/image/home/icon_left_middle_one.png" class="image">
                  <p class="count-info">
                    <span class="count-txt">游客人数统计</span>
                    <span class="count-num">{{ pageInfo.touristTotal }} <i>人</i></span>
                  </p>
                </li>
                <li class="count-item">
                  <img src="@/assets/image/home/icon_left_middle_two.png" class="image">
                  <p class="count-info">
                    <span class="count-txt">产业产值</span>
                    <span class="count-num">{{ pageInfo.pvTotal }} <i>万元</i></span>
                  </p>
                </li>
              </ul>

              <div class="floor-two">
                <ul class="left-count">
                  <li class="count-item">
                    <span>商户</span>
                    <span class="count-num">{{pageInfo.businessTotal}}<i>家</i></span>
                  </li>
                  <li class="count-item">
                    <span>东田话题数</span>
                    <span class="count-num">{{ pageInfo.topicTotal }}<i>次</i></span>
                  </li>
                  <li class="count-item">
                    <span>短视频播放量</span>
                    <span class="count-num">{{ pageInfo.vvTotal }}<i>条</i></span>
                  </li>
                  <li class="count-item">
                    <span>直播销售额</span>
                    <span class="count-num">{{ pageInfo.roi }}<i>万元</i></span>
                  </li>
                </ul>

                <div class="right-swiper">
                  <CustomSwiper :data-list="customSwiperData"></CustomSwiper>
                  <!-- <div class="swiper"></div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- left bottom -->
          <div class="left-bottom-card bottom-card">
            <div class="title">
              <span>地摊经济</span>
              <img src="@/assets/image/home/img_more_btn.png" alt="" @click="jumpPage">
            </div>
            <div class="content">
              <ul class="floor-one">
                <li class="item">
                  <img src="@/assets/image/home/icon_left_bottom_one.png" alt="" class="image">
                  <ul class="count">
                    <li>摊位数（个）</li>
                    <li>{{ pageInfo.stallTotal }}</li>
                  </ul>
                </li>
                <li class="item">
                  <img src="@/assets/image/home/icon_left_bottom_two.png" alt="" class="image">
                  <ul class="count">
                    <li>已出租（个）</li>
                    <li>{{ pageInfo.stallHireTotal }}</li>
                  </ul>
                </li>
              </ul>
              <div class="floor-two">
                <DoubleSwiper :data-list="doubleSwiperData"></DoubleSwiper>
              </div>

              <ul class="floor-three">
                <li>
                  <a href="https://vivid.saas.ictsoft.cn/dplus/view/1701533429436198913?note=9v1yp6aorp&hasPassword=0" class="link">产业数据中心</a>
                </li>
                <li>
                  <a href="javascript:;" class="link">邻里中心</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="middle-section">
          <div class="center-top-card">

            <ul class="item">
              <li class="title">
                <span>村域面积</span>
              </li>
              <li class="info">
                <span>{{ pageInfo.villageArea }}</span>
                <span>平方公里</span>
              </li>
            </ul>
            <ul class="item">
              <li class="title">
                <span>人口总数</span>
              </li>
              <li class="info">
                <span>{{ pageInfo.populationTotal }}</span>
                <span>人</span>
              </li>
            </ul>
            <ul class="item">
              <li class="title">
                <span>村户总数</span>
              </li>
              <li class="info">
                <span>{{ pageInfo.villageTotal }}</span>
                <span>个</span>
              </li>
            </ul>
            <ul class="item">
              <li class="title">
                <span>小组数</span>
              </li>
              <li class="info">
                <span>{{ pageInfo.groupTotal }}</span>
                <span>个</span>
              </li>
            </ul>

          </div>
          <div class="center-map-card">
            <MapContainer></MapContainer>
          </div>
        </div>

        <div class="right-section">

          <div class="right-top-card top-card">
            <div class="title">云上乡贤</div>
            <div class="swiper-box">
              <pageSwiper :data-list="pageSwiperData"></pageSwiper>
            </div>
          </div>

          <div class="right-middle-card middle-card">
            <div class="title">乡村特产</div>
            <div class="content">
              <ul class="count-list">
                <li class="item">
                  <div class="icon"><img src="@/assets/image/home/img_right_middle_one.png" alt=""></div>
                  <div class="count">
                    <p>企业</p>
                    <p>{{ pageInfo.companyTotal }} <span>家</span></p>
                  </div>
                </li>
                <li class="item">
                  <div class="icon"><img src="@/assets/image/home/img_right_middle_two.png" alt=""></div>
                  <div class="count">
                    <p>产量</p>
                    <p>{{ pageInfo.comProTotal }} <span>万元</span></p>
                  </div>
                </li>
                <li class="item">
                  <div class="icon"><img src="@/assets/image/home/img_right_middle_three.png" alt=""></div>
                  <div class="count">
                    <p>产值</p>
                    <p>{{ pageInfo.comPvTotal }} <span>万元</span></p>
                  </div>
                </li>
              </ul>

              <div style="width: 100%;">
                <GoodsSwiper :data-list="goodsSwiperData"></GoodsSwiper>
              </div>
            </div>
          </div>

          <div class="right-bottom-card bottom-card">
            <div class="title">意见反馈</div>
            <div class="content">
              <ul class="floor-one">
                <li class="item">
                  <img src="@/assets/image/home/icon_left_bottom_one.png" alt="" class="image">
                  <ul class="count">
                    <li>意见总数</li>
                    <li>{{ pageInfo.opinionTotal }} <span>条</span></li>
                  </ul>
                </li>
                <li class="item">
                  <img src="@/assets/image/home/icon_left_bottom_two.png" alt="" class="image">
                  <ul class="count">
                    <li>回复量</li>
                    <li>{{ pageInfo.opinionReplyTotal }} <span>条</span></li>
                  </ul>
                </li>
                <li class="item">
                  <img src="@/assets/image/home/icon_left_bottom_two.png" alt="" class="image">
                  <ul class="count">
                    <li>意见回复率</li>
                    <li>{{((pageInfo.opinionReplyTotal || 0) / (pageInfo.opinionTotal|| 0)  * 100).toFixed()}}%</li>
                  </ul>
                </li>
              </ul>

              <div class="scroll-borard">
                <dv-scroll-board :config="config" @click="showOpinionModal"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </FullScreenContainer>

    <ModalCard title="意见反馈" :visible.sync="visible" @close="closeModal" el=".screen-main">
      <ul class="home_modal_wrapper">
        <li>
          <p>建议</p>
          <textarea name="" id="" cols="30" rows="10" readonly :value="opinions.content"></textarea>
        </li>
        <li>
          <p>回复</p>
          <textarea name="" id="" cols="30" rows="10" readonly :value="opinions.replyContent"></textarea>
        </li>
      </ul>
    </ModalCard>

  </div>
</template>

<script>
import { getScreenData } from "@/api/home";

import FullScreenContainer from "@/components/FullScreenContainer";
import CustomSwiper from "./components/CompSwiper";
import DoubleSwiper from "./components/DoubleSwiper";
import PageSwiper from "./components/PageSwiper";
import GoodsSwiper from "./components/GoodsSwiper";
import MapContainer from "./components/MapContainer";
import ModalCard from "@/components/ModalCard";

export default {
  components: { FullScreenContainer, CustomSwiper, DoubleSwiper, PageSwiper, GoodsSwiper, MapContainer, ModalCard },
  data() {
    return {
      pageInfo: {},
      customSwiperData: [],
      doubleSwiperData: [],
      pageSwiperData: [],
      goodsSwiperData: [],
      loading: true,
      videoConfig: {
        url: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
      },
      isMuted: true,
      config: {
        columnWidth: [50],
        data: [],
        oddRowBGC: "#142955",
        evenRowBGC: "#142955",
      },
      visible: false,
      opinions: {
        content: "",
        replyContent: ""
      }
    }
  },
  methods: {
    jumpPage() {
      this.$router.push("/view");
    },
    showOpinionModal({rowIndex}) {
      const opinions = this.pageInfo.opinions[rowIndex];
      this.opinions = { 
        content: opinions.content,
        replyContent: opinions.replyContent
      }
      this.visible = true;
    },
    closeModal(val) {
      this.visible = val;
    }
  },
  mounted() {

    getScreenData().then(res => {
      const { data } = res || {};
      const opinions = data.opinions || [];

      this.pageInfo = data;
      this.customSwiperData = data.scenicSpotListDtos || [];
      this.doubleSwiperData = data.stallVos || [];
      this.pageSwiperData = data.villageVirtuouss || [];
      this.goodsSwiperData = data.productDtos || [];
      this.config = {
        columnWidth: [ 50 ],
        data: opinions.map(item => {
          return [
            '<div class="icon-parent"><p class="icon-circle"></p></div>',
            `<div class="opinion"><span>${item.content}</span><span>${item.status == 20 ? "已回复" : ""}</span></div>`
          ]
        }),
        oddRowBGC: "#142955",
        evenRowBGC: "#142955",
      }

      this.$refs.customVideo.src = data.villageUrl;
    })

    setTimeout(_ => {
      this.loading = false;
    }, 2000)
  }
}
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.home_modal_wrapper {
  color: #fff;
  li:nth-child(1) {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
  }
  textarea {
    width: 100%;
    height: 160px;
    outline:none;
    resize:none;
    padding: 10px;
    border: 0;
    background: #112B5F;
  }
  .submit_btn {
    height: 30px;

  }
}
.loading {
  transition: all 1s;
  height: 100%;
  // background: rgba(0,0,0, .6);
}

.screen-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  .left-section,
  .right-section {
    width: 23%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .title {
      height: 56px;
      padding-left: 38px;
      margin-bottom: 10px;
      background: url("@/assets/image/home/bg_title.png") no-repeat;
      background-size: contain;
      font-size: 22px;
      color: #FFFFFF;
      line-height: 56px;
    }

    .top-card {
      height: 28%;
      min-height: 270px;
    }

    .middle-card {
      height: 31%;
      min-height: 300px;
    }

    .bottom-card {
      height: 37%;
      min-height: 360px;
    }
  }

}

.main {

  .count-txt {
    line-height: 22px;
    font-size: 16px;
    color: #DCE9F4;
  }

  .count-num {
    font-size: 22px;
    color: #30FDFF;

    i {
      font-size: 14px;
      vertical-align: 2px;
    }
  }

}

.left-section {

  .left-top-card {

    .content {
      height: calc(100% - 66px);
      padding: 14px;
      background: url("@/assets/image/home/bg_left_top.png") no-repeat;
      background-size: 100% 100%;

      .video {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: fill
      }
    }
  }

  .left-middle-card {
    min-height: 300px;
    // margin-bottom: 10px;

    .content {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: calc(100% - 66px);

      .floor-one {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .count-item {
          width: 50%;
          display: flex;

          .image {
            width: 87px;
            height: 56px;
            margin-right: 10px;
          }

          .count-info {
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 3px 0;
          }

        }
      }

      .floor-two {
        display: flex;
        width: 100%;
        padding-top: 10px;

        .left-count {
          width: 58%;
          flex: 1;
          display: flex;
          flex-flow: column wrap;
          justify-content: space-between;

          .count-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            padding: 0 20px;
            background: url("@/assets/image/home/bg_left_middle_count.png") no-repeat;
            background-size: 100% 100%;

            &:last-child {
              margin: 0;
            }

            & span:nth-child(1) {
              color: #fff;
              font-size: 14px;
            }

            & span:nth-child(2) {
              font-size: 22px;
            }
          }
        }

        .right-swiper {
          width: 42%;
          height: 168px;
          padding: 2px;
          margin-left: 10px;
          background: #7AE0FF;

          .swiper {
            width: 100%;
            height: 100%;
            background: #fff;
          }
        }
      }
    }
  }

  .left-bottom-card {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;

      img {
        cursor: pointer;
      }
    }

    .content {
      height: calc(100% - 66px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .floor-one {
      display: flex;

      & .item:nth-child(1) {
        margin-right: 10px;
      }

      .item {
        display: flex;
        width: 50%;
        height: 62px;
        padding-left: 32px;
        align-items: center;
        justify-content: flex-start;
        background: url("@/assets/image/home/bg_left_bottom_count.png") no-repeat;
        background-size: 100% 100%;

        .count {
          padding-top: 4px;

          & li:nth-child(1) {
            font-size: 14px;
            line-height: 20px;
            color: #fff;
          }

          & li:nth-child(2) {
            font-size: 20px;
            line-height: 24px;
            color: #30FDFF;
          }
        }

        .image {
          width: 40px;
          height: 38px;
          margin-right: 18px;
        }
      }
    }

    .floor-two {
      margin: 10px 0;
    }

    .floor-three {
      display: flex;
      align-items: center;

      li {
        width: 50%;
        height: 46px;
        background: url("@/assets/image/home/bg_left_bottom_btn.png") no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 46px;
        text-align: center;
      }

      li:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}

.middle-section {
  flex: 1;
  height: 100%;
  margin: 0 60px;
  padding-top: 20px;

  .center-top-card {
    height: 138px;
    background: url("@/assets/image/home/bg_middle_title.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      width: 20%;
      // background: #30FDFF;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        font-size: 18px;

        &::before {
          background: url("@/assets/image/home/bg_ar_l.png") 100% no-repeat;
        }

        &::after {
          background: url("@/assets/image/home/bg_ar_r.png") no-repeat;
        }

        &::before,
        &::after {
          content: " ";
          display: block;
          width: 25px;
          height: 28px;
          background-size: contain;
        }
      }

      .info {
        span:nth-child(1) {
          font-size: 28px;
          color: #FFCD3A;
          font-weight: 600;
          letter-spacing: 2px;
        }

        span:nth-child(2) {
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }
  }

  .center-map-card {
    position: relative;
    width: 100%;
    height: calc(100% - 138px);
  }
}

.right-section {
  width: 23%;

  .title {
    height: 56px;
    padding-left: 38px;
    margin-bottom: 10px;
    background: url("@/assets/image/home/bg_title.png") no-repeat;
    background-size: contain;
    font-size: 22px;
    color: #FFFFFF;
    line-height: 56px;
  }

  .right-top-card {
    width: 100%;
    overflow: hidden;

    .swiper-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - 66px);
    }
  }

  .right-middle-card {
    .content {
      height: calc(100% - 66px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .count-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .item {
        .icon {
          width: 87px;
          height: 56px;
        }

        .count {
          color: #fff;
          padding: 10px 0 0 20px;
          font-size: 16px;

          p:nth-child(1) {
            font-size: 16px;
            font-weight: 500;
            color: #DCE9F4;
            line-height: 22px;
          }

          p:nth-child(2) {
            padding-top: 6px;
            font-size: 20px;
            font-weight: bold;
            color: #30FDFF;
            line-height: 24px;

            span {
              font-weight: normal;
              font-size: 14px;
              vertical-align: 1px;
            }
          }
        }
      }
    }


  }

  .right-bottom-card {
    .content {
      height: calc(100% - 66px);
    }

    .floor-one {
      display: flex;

      .item {
        display: flex;
        width: 33%;
        height: 62px;
        padding-left: 10px;
        margin-right: 10px;
        align-items: center;
        justify-content: flex-start;
        background: url("@/assets/image/home/bg_left_bottom_count.png") 100% 100% no-repeat;
        background-size: 100% 100%;

        .count {
          padding-top: 4px;

          & li:nth-child(1) {
            font-size: 14px;
            line-height: 20px;
            color: #fff;
          }

          & li:nth-child(2) {
            font-size: 20px;
            line-height: 24px;
            color: #30FDFF;
          }

          span {
            font-size: 14px;
          }
        }

        .image {
          width: 40px;
          height: 38px;
          margin-right: 10px;
        }
      }
    }

    .scroll-borard {
      width: 100%;
      height: calc(100% - 62px);
      padding-top: 10px;
    }
  }
}

.link {
  color: #fff;
  text-decoration: none; 
  cursor: pointer;
}
</style>


<style lang="less">
.opinion {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  color: #87C4EB;

  span:nth-child(1) {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }

  span:nth-child(2) {
    width: 30px;
  }
}

.dv-scroll-board .rows .row-item:hover {
  .ceil {
    .opinion {
      color: #fff;
      background: linear-gradient(270deg, #284788 0%, rgba(8, 25, 49, 0) 100%);
    }
  }
}

.dv-scroll-board .rows .ceil .icon-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dv-scroll-board .rows .ceil .icon-circle {

  width: 5px;
  height: 5px;
  background: rgba(24, 63, 111, 0.4);
  box-shadow: 0px 0px 4px 0px #9FEBFF;
  border: 1px solid #16CFFE;

}
</style>