<template>
  <FullScreenContainer>
    <div class="navigation">
      <div class="back_btn" @click="goBack"><span>返回</span></div>
      <ul class="btn_group">
        <li class="item">
          <img src="@/assets/image/home/icon_left_bottom_one.png" alt="" class="image">
          <ul class="count">
            <li>摊位数（个）</li>
            <li>{{ total }}</li>
          </ul>
        </li>
        <li class="item">
          <img src="@/assets/image/home/icon_left_bottom_two.png" alt="" class="image">
          <ul class="count">
            <li>已出租（个）</li>
            <li>{{ leased }}</li>
          </ul>
        </li>
      </ul>

      <div class="search">
        <input class="search_inp" v-model.trim="stallName" />
        <div class="search_btn" @click="searchList">搜索</div>
      </div>
    </div>

    <dv-border-box-8 class="stall_wrapper" :dur="10">
      <happyScroll resize hide-horizontal size="8" ref="scroll" color="rgba(51,51,51,0)" @vertical-end="handlerLoadMore">
        <div class="stall_list" v-show="list.length">
          <BoothCardItem class="booth-item" v-for="(item, index) in list" source="list" :key="index" :item="item"
            @on-close="showModal(item)"></BoothCardItem>
        </div>
        <div class="empty" v-show="list.length == 0">
          <span>暂无数据</span>
        </div>
      </happyScroll>
    </dv-border-box-8>

    <ModalCard title="地摊经济" :visible.sync="visible" @close="close">
      <BoothCardItem source="modal" :item="modalData"></BoothCardItem>
    </ModalCard>

  </FullScreenContainer>
</template>

<script>
import { HappyScroll } from 'vue-happy-scroll';

import { getStallData } from "@/api/list";

import FullScreenContainer from "@/components/FullScreenContainer";
import ModalCard from "@/components/ModalCard";
import BoothCardItem from "./components/BoothCardItem";

import 'vue-happy-scroll/docs/happy-scroll.css';

export default {
  components: { FullScreenContainer, HappyScroll, ModalCard, BoothCardItem },
  data() {
    return {
      visible: false,
      stallName: "",
      leased: 0,
      list: [],
      modalData: {},
      settings: {
        swicher: true,
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 100,
        // isCount:1
      },
      total: 0
    }
  },
  methods: {
    close(val) {
      this.visible = val;
    },
    showModal(val) {
      this.visible = true;
      this.modalData = val;
    },
    goBack() {
      this.$router.push("/view");
    },
    handlerLoadMore() {
      // const { pageNum, pageSize } = this.pageInfo;
      // if (pageNum * pageSize < this.total) {
      //   this.getList();
      // }
    },
    searchList() {
      this.list = [];
      this.getList();
    },
    getNum(str) {
      const strArr = str.split("号");
      if (strArr.length != 2 || isNaN(strArr[0])) {
        return new Date().getTime();
      }
      return strArr[0];
    },
    getList() {
      // this.pageInfo.pageNum ++;
      getStallData({ pageInfo: this.pageInfo, stallName: this.stallName, stallNumber: this.$route.query.stallNumber }).then(({rows, total}) => {
        // this.list.concat
        this.list = rows.sort((a, b) => {
          const pre = this.getNum(a.stallName);
          const lat = this.getNum(b.stallName);
          return pre - lat;
        });
        this.total = total;
        if ( rows && rows.length ) {
          this.leased = rows.filter(item => item.stallStatus == 20).length;
        }
      });
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.scroll-area {
  position: relative;
  width: 100%;
  height: 100%;
  width: 600px;
  height: 400px;
  background: red;
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
  color: #fff;

  .back_btn {
    width: 122px;
    height: 44px;
    padding-left: 55px;
    margin-right: 25px;
    background: url("@/assets/image/list/bg_back.png") no-repeat center center;
    background-size: 100% 100%;
    line-height: 44px;
    cursor: pointer;
  }

  .btn_group {
    display: flex;

    & .item:nth-child(1) {
      margin-right: 10px;
    }

    .item {
      display: flex;
      width: 208px;
      height: 62px;
      padding-left: 32px;
      align-items: center;
      justify-content: flex-start;
      background: url("@/assets/image/home/bg_left_bottom_count.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      .count {
        padding-top: 4px;

        & li:nth-child(1) {
          font-size: 14px;
          line-height: 20px;
          color: #fff;
        }

        & li:nth-child(2) {
          font-size: 20px;
          line-height: 24px;
          color: #30FDFF;
        }
      }

      .image {
        width: 40px;
        height: 38px;
        margin-right: 18px;
      }
    }
  }

  .search {
    flex: 1;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search_inp {
      flex: 1;
      height: 100%;
      padding: 0 20px;
      margin: 0 20px;
      background: #002558;
      box-shadow: inset 0px 0px 9px 0px rgba(74, 144, 226, 0.72);
      border-radius: 6px;
      border: 1px solid #0050A6;
      color: #fff;

      &:focus {
        border-radius: 3px;
        border: 0;
        outline: 3px solid #0050A6;
        box-shadow: 1px 1px 10px 1px #0050A6;
      }
    }

    .search_btn {
      width: 90px;
      height: 100%;
      background: url("@/assets/image/list/bg_search_btn.png") no-repeat center center;
      background-size: 100% 100%;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.stall_wrapper {
  padding: 20px 40px;
  height: calc(100% - 102px);
  overflow: hidden;

  .happy-scroll-container {
    width: 100%;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
    color: #fff;
  }

  .stall_list {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    overflow: hidden;

    .booth-item {
      width: 25%;
      height: 176px;
      margin-bottom: 30px;
      cursor: pointer;
      height: auto;
    }
  }
}
</style>

<style lang="less">
.happy-scroll .happy-scroll-container:first-child {
  width: 100% !important;
  height: 100% !important;
}


.happy-scroll-content {
  width: 100% !important;
}
</style>