<template>
  <div class="page-swiper-container">
    <swiper class="swiper" ref="swiper" :options="swiperComponentOption">
      <swiper-slide v-for="item in dataList" :key="item.villageVirtuousId">
        <div class="user-wrapper">
          <div class="item">
            <div class="top">
              <div class="cover">
                <img :src="item.filePath" alt="" class="image">
              </div>
              <span>{{ item.villageVirtuousName }}</span>
            </div>
            <div class="user-info">
              <span class="name">{{ item.position }}</span>
              <p class="desc">
                <span>{{ item.company }}</span>
                <span>{{ item.intro }}</span>
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <img src="@/assets/image/home/img_btn.png" class="right-btn" disabled>
  </div>

</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      list: [23,4],
      swiperComponentOption: {
        autoplay: {
          delay: 4 * 1000,
          disableOnInteraction: false
        },
        loop: true,
        loopFillGroupWithBlank: true,
        observer: true,
        observeParents: false,
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 30,
        navigation: {
          nextEl: ".right-btn"
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.update();
          }
        }
      },
      swiperList: []
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.swiper?.$swiper.update();
      })
    }, 200);
  }
};
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.page-swiper-container {
  width: 100%;
  display: flex;
  align-items: center;

  .swiper {
    flex: 1;
  }

  .right-btn {
    width: 30px;
    height: 45px;
    cursor: pointer;
  }

  .user-wrapper {
    // flex: 1;
    height: 100%;
    // display: flex;

    .item {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin-right: 24px;

      &:last-child {
        margin: 0;
      }

      .top {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;
        height: 112px;
        padding: 8px 0 4px 0;
        background: url("@/assets/image/home/bg_right_top_cover.png") no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;

        .cover {
          width: 68px;
          height: 68px;
          border-radius: 100%;
          overflow: hidden;
          background: #fff;
          .image {
            width: 100%;
            height: 100%;
          }
        }
      }

      .user-info {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: calc(100% - 112px);
        font-size: 14px;
        color: #87C4EB;
        line-height: 20px;

        span {
          display: block;
        }

        .name {
          text-align: center;
        }

        .desc {
          height: 60px;
          // padding-top: 6px;

          span:nth-child(1) {
            .overLine(1);
          }

          span:nth-child(2) {
            .overLine(3);
          }
        }

      }
    }
  }
}


// .page-swiper-container {
//   display: flex;
// }

// .swiper-container {
//   width: 100%;
//   height: 100%;

//   .swiper-slide {
//     width: 50%;
//     height: 100%;
//     // background: blanchedalmond;
//   }
// }

// .swiper {
//   width: 200px;
//   position: relative;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;

//   .swiper-slide {
//     // width: auto;
//   }
// }


// .user-box {
//   // display: flex;
//   // align-items: center;

//   .right-btn {
//     width: 30px;
//     height: 45px;
//   }
// }


// .swiper-box {
//   height: calc(100% - 66px);
//   // display: flex;
//   // align-items: center;
//   // justify-content: space-between;
//   // margin-bottom: 14px;

//   .right-btn {
//     cursor: pointer;
//   }
</style>