export const coordinates = [
  [
    [
      120.3704614041952,
      30.59295492113886,
      0
    ],
    [
      120.3705212422682,
      30.59215399054726,
      0
    ],
    [
      120.3696930718479,
      30.59209273867621,
      0
    ],
    [
      120.3698016913292,
      30.5906562315865,
      0
    ],
    [
      120.3689179824488,
      30.59064744253075,
      0
    ],
    [
      120.3689994992036,
      30.58962990428978,
      0
    ],
    [
      120.3679471422139,
      30.58965008851829,
      0
    ],
    [
      120.3680701975249,
      30.58813239690448,
      0
    ],
    [
      120.3673486082088,
      30.58794496726729,
      0
    ],
    [
      120.3675696447634,
      30.58657924366704,
      0
    ],
    [
      120.3667549859143,
      30.58635959105401,
      0
    ],
    [
      120.3664023546259,
      30.58617380144562,
      0
    ],
    [
      120.3654940721957,
      30.58615179684384,
      0
    ],
    [
      120.3647753826881,
      30.58789965006605,
      0
    ],
    [
      120.3642354489686,
      30.58778900602919,
      0
    ],
    [
      120.3642559935498,
      30.58703525851512,
      0
    ],
    [
      120.363060958268,
      30.58649515041068,
      0
    ],
    [
      120.3613133379428,
      30.58738776622357,
      0
    ],
    [
      120.3610539942816,
      30.58637358756006,
      0
    ],
    [
      120.359441861393,
      30.58533761845938,
      0
    ],
    [
      120.3576239266502,
      30.5844614261291,
      0
    ],
    [
      120.3583836529345,
      30.58352783623527,
      0
    ],
    [
      120.3587909513769,
      30.582339290482,
      0
    ],
    [
      120.3602746135366,
      30.58280682640549,
      0
    ],
    [
      120.3639718162302,
      30.58292783326394,
      0
    ],
    [
      120.3648512440116,
      30.58346033395851,
      0
    ],
    [
      120.3665496572367,
      30.58415517532518,
      0
    ],
    [
      120.3682933923,
      30.58422941285266,
      0
    ],
    [
      120.3684748439084,
      30.58278196400979,
      0
    ],
    [
      120.3715470310794,
      30.581972154194,
      0
    ],
    [
      120.371230380997,
      30.58047546552698,
      0
    ],
    [
      120.3725802896059,
      30.58008780252941,
      0
    ],
    [
      120.3725108827243,
      30.57950423627025,
      0
    ],
    [
      120.3728189723237,
      30.57931931949596,
      0
    ],
    [
      120.3727985890661,
      30.57875400857281,
      0
    ],
    [
      120.3760402109619,
      30.57765806460398,
      0
    ],
    [
      120.3766647843865,
      30.57764169221229,
      0
    ],
    [
      120.3764241712505,
      30.57824995875647,
      0
    ],
    [
      120.376796641566,
      30.5799779375498,
      0
    ],
    [
      120.3789396739662,
      30.58021339459737,
      0
    ],
    [
      120.3790205902249,
      30.58077444586176,
      0
    ],
    [
      120.3796883933037,
      30.58073727977111,
      0
    ],
    [
      120.3802087498833,
      30.58247982989408,
      0
    ],
    [
      120.3804082465033,
      30.58601031721296,
      0
    ],
    [
      120.3808608557401,
      30.58770294243365,
      0
    ],
    [
      120.3814829856045,
      30.58809418391598,
      0
    ],
    [
      120.382558896508,
      30.58876895651094,
      0
    ],
    [
      120.3830162989129,
      30.58879221412407,
      0
    ],
    [
      120.3833783187302,
      30.58923838540847,
      0
    ],
    [
      120.3845122621888,
      30.58933588183042,
      0
    ],
    [
      120.3864673980919,
      30.58909943042481,
      0
    ],
    [
      120.3863608546545,
      30.59079875237395,
      0
    ],
    [
      120.3808966752867,
      30.59210822690051,
      0
    ],
    [
      120.3804844835383,
      30.59092277371081,
      0
    ],
    [
      120.3792643409466,
      30.58993695600903,
      0
    ],
    [
      120.3782746987017,
      30.5897757774616,
      0
    ],
    [
      120.3781213963069,
      30.59169064043025,
      0
    ],
    [
      120.3769426234628,
      30.59152588198847,
      0
    ],
    [
      120.3770333041846,
      30.59305433929573,
      0
    ],
    [
      120.3701794641299,
      30.59447567926444,
      0
    ],
    [
      120.3704614041952,
      30.59295492113886,
      0
    ]
  ]
]