<template>
  <section class="container">
    <header class="header">
      <h1 class="title">{{ title }}</h1>
      <ul class="time">
        <li>{{ dateYear }}</li>
        <li>{{ dateDay }}</li>
      </ul>
    </header>

    <section class="main">
      <slot></slot>
    </section>

  </section>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    title: {
      type: String,
      default: "东田村未来乡村驾驶舱"
    }
  },
  data() {
    return {
      loading: true,
      dateYear: null,
      dateDay: null,
      timer: null
    }
  },
  methods: {
    getTime() {
      const date = dayjs();
      this.dateYear = date.format('YYYY-MM-DD');
      this.dateDay = date.format('HH:mm:ss');
    }
  },
  mounted() {
    this.getTime();
    setInterval(_ => this.getTime(), 1 * 1000);
  },
  destroyed() {
    clearInterval( this.timer );
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background: url("@/assets/image/home/bg_container.png") no-repeat center center;
  background-size: 100% 100%;
}

.header {
  position: relative;
  width: 100%;
  height: 180px;
  padding: 5px 20px 0 20px;
  background: url("@/assets/image/home/bg_header.png") no-repeat center center;
  background-size: 100% 100%;
  text-align: center;

  .title {
    color: #FFFFFF;
    line-height: 59px;
    letter-spacing: 4px;
    font-size: 40px;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgba(22, 208, 255, 0.7);
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #A2CDFF;

    & li:nth-child(1) {
      margin-right: 20px;
    }
  }
}

.main {
  position: absolute;
  top: 90px;
  width: 100%;
  height: calc(100% - 90px);
  padding: 0 20px 30px 20px;
  background: url("@/assets/image/home/bg_container_bottom.png") left bottom no-repeat;
  background-size: contain;
}
</style>