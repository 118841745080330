<template>
  <swiper ref="swiperComponentRef" class="swiper" :options="swiperComponentOption">
    <swiper-slide v-for="(item) in dataList" :key="item.stallId">
      <div class="swiper-item">
        <div class="image">
          <img :src="item.filePath" alt="">
          <span>{{ item.stallName }}</span>
        </div>
        <ul class="businsess-info">
          <li>
            <span>商户:</span>
            <span>{{ item.businessName }}</span>
          </li>
          <li>
            <span>联系方式:</span>
            <span>{{ item.phone }}</span>
          </li>
          <li>
            <span>销售产品:</span>
            <span>{{ item.productType }}</span>
          </li>
        </ul>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dataList(list) {
      if ( list.length <= 2 ) {
        this.$refs.swiperComponentRef.$swiper.params.slidesPerView = list.length;
        this.$refs.swiperComponentRef?.$swiper.update();
      }
     
    }
  },
  data() {
    return {
      list: [{}],

      swiperComponentOption: {
        autoplay: {
          delay: 4 * 1000,
          disableOnInteraction: false
        },
        loop: true,
        observer: true,
        observeParents: true,
        loopedSlides: 15,               // 在loop 模式下使用 slidesPerview:'auto' ，还需使用该参数设置所要用到的loop 个数(一般设置大于可视slide个数2个即可)。
        // slidesPerGroupAuto: true,   // autoplay 会自动为可视范围内的Slides 分组。  自动分组
        slidesPerGroup: 1,             // 在carousel mode下定义slides的数量多少为一组。
        slidesPerView: 'auto',         // 设置slider容器能够同时显示的slides数量(carousel模式)。
        spaceBetween: 30,
      },
    };
  },
  mounted() {
    setTimeout(_ => {
      this.$nextTick(_ => {
        // this.$refs.swiperComponentRef?.$swiper.update();
      })
    }, 500);
  }
};
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.swiper {
  position: relative;
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: auto;
  }
}

.swiper-pagination {
  bottom: 30px !important;
}


.swiper-item {
  // width: 100%;
  // background: red;
  // display: flex;
  // width: 160px;
  // flex-flow: column;
  width: 160px;

  .image {
    position: relative;
    width: 160px;
    height: 100px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    color: #fff;

    img {
      width: 100%;
      height: 100%;
      border: 1px solid #16CFFE;
      background: #ccc;
    }

    span {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 26px;
      background: rgba(3, 20, 57, 0.7);
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
  }

  .businsess-info {
    width: 160px;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #87C4EB;
    line-height: 20px;

    li {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
  }
}
</style>