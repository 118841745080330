<template>
  <FullScreenContainer title="东田村未来乡村驾驶舱-视频监控">
    <div class="navigation">
      <div class="back_btn" @click="goBack"><span>返回</span></div>
    </div>

    <dv-border-box-8 class="comp_video_wrapper" :dur="10">
      <div class="video_container">
        <div class="video_window">
          <div class="video"></div>
        </div>

        <div class="video_list_box">
          <happyScroll resize hide-horizontal size="8" ref="scroll" color="rgba(51,51,51,0)"
            @vertical-end="handlerLoadMore">
            <ul class="video_list">
              <li class="video_item" v-for="item in 3" :key="item">
                <p class="name">1号机</p>
                <div class="camera">
                  <div class="inside_box">
                    <div class="icon">
                      <img src="@/assets/image/video/bg_video_icon.png" alt="" class="image">
                    </div>
                    <div class="address">逸仙路299号逸仙路299号逸仙路299</div>
                  </div>
                </div>
              </li>
            </ul>
          </happyScroll>
        </div>
      </div>
      <!-- <div class="empty">
        <span>暂无数据</span>
      </div> -->
    </dv-border-box-8>

    <ModalCard title="地摊经济" :visible.sync="visible" @close="close">
      <BoothCardItem source="modal"></BoothCardItem>
    </ModalCard>

  </FullScreenContainer>
</template>

<script>
import { HappyScroll } from 'vue-happy-scroll';

import FullScreenContainer from "@/components/FullScreenContainer";
import ModalCard from "@/components/ModalCard";
import BoothCardItem from "./components/BoothCardItem";

import 'vue-happy-scroll/docs/happy-scroll.css';

export default {
  components: { FullScreenContainer, HappyScroll, ModalCard, BoothCardItem },
  data() {
    return {
      visible: false,
      value: "",
      list: new Array(26).fill({ a: 1 }),
      settings: {
        swicher: true,
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      }
    }
  },
  methods: {
    close(val) {
      this.visible = val;
    },
    showModal(val) {
      this.visible = true;
    },
    goBack() {
      this.$router.push("/");
    },
    handlerLoadMore() {
    }
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
.overLine(@clamp: 1) {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @clamp;
  -webkit-box-orient: vertical;
}

.scroll-area {
  position: relative;
  width: 100%;
  height: 100%;
  width: 600px;
  height: 400px;
  background: red;
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
  color: #fff;

  .back_btn {
    width: 122px;
    height: 44px;
    padding-left: 55px;
    margin-right: 25px;
    background: url("@/assets/image/list/bg_back.png") no-repeat center center;
    background-size: 100% 100%;
    line-height: 44px;
    cursor: pointer;
  }
}

.comp_video_wrapper {
  padding: 30px;
  height: calc(100% - 102px);
  overflow: hidden;

  .video_container {
    width: 100%;
    height: 100%;

    .video_window {
      height: 460px;
      padding: 16px;
      background: url("@/assets/image/video/bg_video.png") no-repeat;
      background-size: 100% 100%;

      .video {
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
  }

  .video_list_box {
    height: calc(100% - 460px);
    padding-top: 14px;
  }
  .video_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    .video_item {

      flex: 1;
      margin: 0 20px 20px 0;
      width: calc((100% - 80px) / 5);
      min-width: calc((100% - 80px) / 5);
      max-width: calc((100% - 80px) / 5);
      font-size: 16px;
      font-weight: 500;
      color: #A2CDFF;
      line-height: 22px;

      &:nth-child(5n) {
        margin-right: 0;
      }

      .name {
        padding-bottom: 10px;
      }

      .camera {
        display: flex;
        align-items: center;
        width: 100%;
        height: 120px;
        padding: 20px 20px;
        background: url("@/assets/image/video/bg_video_btn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        .inside_box {
          display: flex;
          align-items: flex-start;
          line-height: 20px;

          .icon {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
              width: 50px;
              height: 50px;
            }
          }

          .address {
            flex: 1;
            .overLine(3);
          }
        }
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
    color: #fff;
  }


}
</style>

<style lang="less">
.happy-scroll .happy-scroll-container:first-child {
  width: 100% !important;
  height: 100% !important;
}
.happy-scroll-content {
  width: 100% !important;
}
</style>