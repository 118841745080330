<template>
  <section class="map-wrapper">
    <div id="map-viewport"></div>
    <ul class="side-list">
      <li
        v-for="(item, index) in mapIconList"
        :key="item.key"
        :class="['legend', item.isChecked ? 'checked' : 'unchecked']"
        @click="handlerLegend(item, index)"
      >
        <img :src="item.icon" alt="" />
        <span>{{ item.val }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

import iconMapOne from "@/assets/image/home/icon_map_one.png";
import iconMapTwo from "@/assets/image/home/icon_map_two.png";
import iconMapThree from "@/assets/image/home/icon_map_three.png";
import iconMapFour from "@/assets/image/home/icon_map_four.png";
import iconMapFive from "@/assets/image/home/icon_map_five.png";
import iconMapSix from "@/assets/image/home/icon_map_six.png";

import iconMapVideo from "@/assets/image/home/icon-map-video.png";
import iconMapScenic from "@/assets/image/home/icon-map-scenic.png";
import iconMapHotel from "@/assets/image/home/icon-map-hotel.png";
import iconMapCatering from "@/assets/image/home/icon-map-catering.png";
import iconMapParklot from "@/assets/image/home/icon-map-parklot.png";
import iconMapOther from "@/assets/image/home/icon-map-other.png";

import { getMapData } from "@/api/home";

import { getCenter } from "@/utils/tools.js";
import { coordinates } from "@/utils/coordinates.js";

export default {
  data() {
    return {
      map: null,
      AMap: null,
      activeIndex: 1,
      mapIconList: [
        // { bizType: 10, key: "video", val: "摄像头", icon: iconMapOne, isChecked: true },
        { bizType: 20, key: "scenic", val: "景点", icon: iconMapTwo, isChecked: true },
        { bizType: 30, key: "hotel", val: "酒店", icon: iconMapThree, isChecked: true },
        { bizType: 40, key: "catering", val: "餐饮", icon: iconMapFour, isChecked: true },
        { bizType: 50, key: "parklot", val: "停车场", icon: iconMapFive, isChecked: true },
        { bizType: 60, key: "other", val: "其他", icon: iconMapSix, isChecked: true },
      ],
      markerImg: {
        // 10: iconMapVideo,
        "20": iconMapScenic,
        "30": iconMapHotel,
        "40": iconMapCatering,
        "50": iconMapParklot,
        "60": iconMapOther,
      },
      points: [],
      markers: [],
      mapData: {},
      tempMapData: {},
      point: {
        video: [[116.405467, 39.907761]],
        scenic: [[116.415467, 39.907761]],
        hotel: [[116.415467, 39.917761]],
        catering: [[116.425467, 39.907761]],
        parklot: [[116.385467, 39.907761]],
        other: [[116.397428, 39.90923]],
      },
      // videoMarkers: [],
      scenicMarkers: [],
      hotelMarkers: [],
      cateringMarkers: [],
      parklotMarkers: [],
      otherMarkers: [],
      centerPoint: [],
    };
  },
  methods: {
    handlerLegend(item) {
      const {} = item;
      if (item.isChecked) {
        this.map.remove(this[item.key + "Markers"]);
        this[item.key + "Markers"] = [];
        this.mapData[item.key].isShow = false;
      } else {
        const obj = {};
        this.mapData[item.key].isShow = true;
        Object.keys(this.mapData).filter(key => {
          if ( this.mapData[key].isShow ) {
            obj[key] = this.mapData[key]
          }
        })
        this.createMarker(obj);
      }
      item.isChecked = !item.isChecked;
    },

    createMarker(data) {
      Object.keys(data).forEach(key => {
        if (key == "video") return; 
        data[key].forEach(position => {
          var marker = new this.AMap.Marker({
            position: [position.longitude, position.latitude],
            content: `<div class="icon-marker marker-${key}"></div>`,
            map: this.map,
            offset: new AMap.Pixel(-18, -60)
          });
          this[key + 'Markers'].push(marker);
        })
      })
    },
    initMap() {
      AMapLoader.load({
        key: "1ae5794c8bc516548b3f6e71df12a0b0",
        plugins: ["AMap.Object3DLayer", "AMap.ImageLayer", "AMap.PlaceSearch"],
      })
        .then((AMap) => {

          this.AMap = AMap;

          var bounds = JSON.parse(JSON.stringify(coordinates));
          var mask = [];
          for (var i = 0; i < bounds.length; i += 1) {
            mask.push([bounds[i]]);
          }
     
          var map = new AMap.Map("map-viewport", {
            mask: mask,
            expandZoomRange: true,
            center: this.centerPoint,
            disableSocket: true,
            viewMode: "3D",
            showLabel: false,
            labelzIndex: 130,
            pitch: 40,
            zoom: 15,
            zooms: [15, 20],
            layers: [
              new AMap.TileLayer.RoadNet(),
              new AMap.TileLayer.Satellite(),
              // imageLayer
            ],
          });

          this.map = map;

          //添加高度面
          var object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
          map.add(object3Dlayer);

          var height = -400;
          var color = "#0088ffcc";

          var wall = new AMap.Object3D.Wall({
            path: bounds,
            height: height,
            color: color,
          });
          wall.transparent = true;
          object3Dlayer.add(wall);

          //添加描边
          for (var i = 0; i < bounds.length; i += 1) {
            new AMap.Polyline({
              path: bounds[i],
              strokeColor: "#99ffff",
              strokeWeight: 6,
              map: map,
            });
          }

          this.getMapInfo();

          map.setZoom([15, 20]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getMapInfo(bizType = 20) {
      await getMapData({ bizType }).then(({ data }) => {
        Object.keys(data).forEach(key => data[key].isShow = true);
        this.mapData = data;
        this.createMarker(data);
      });
    },
  },
  beforeCreate() {
    window._AMapSecurityConfig = {
      securityJsCode: "27166ebb83726d04f32f4ba1031184a6", // 密钥
    };
  },
  async mounted() {
    this.centerPoint = getCenter(coordinates.flat());
    this.initMap();
  },
  destroyed() {
    this.map?.destroy();
  },
};
</script>

<style lang="less" scoped>
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .custom-content-marker {
    position: relative;
    width: 25px;
    height: 34px;
  }

  .custom-content-marker img {
    width: 100%;
    height: 100%;
  }

  #map-viewport {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0) !important;
  }

  .side-list {
    position: absolute;
    top: 3%;
    right: 3%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .legend {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      opacity: 1;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .checked {
      opacity: 1;
    }

    .unchecked {
      opacity: 0.5;
    }
  }
}


</style>

<style>
.marker-scenic {
  background: url("@/assets/image/home/icon-map-scenic.png") no-repeat;
}
.marker-hotel {
  background: url("@/assets/image/home/icon-map-hotel.png") no-repeat;
}
.marker-catering {
  background: url("@/assets/image/home/icon-map-catering.png") no-repeat;
}
.marker-parklot {
  background: url("@/assets/image/home/icon-map-parklot.png") no-repeat;
}
.marker-other {
  background: url("@/assets/image/home/icon-map-other.png") no-repeat;
}
.icon-marker {
  width: 44px;
  height: 64px;
  background-size: 100% 100%;
}
.amap-logo,
.amap-copyright {
  display: none !important;
}
</style>